
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Button, Center, Flex, Stack } from '@chakra-ui/react'
import type { NextPage } from 'next'
import React from 'react'

import Image404 from '../assets/images/404.svg'
import ActiveLink from '@internals/components/Navbar/ActiveLink'
import { Title } from '@internals/components/Title'

const Custom404: NextPage = () => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" minH="100vh" w="full">
      <Stack spacing={10}>
        <Title title="The page you were looking does not exist" />
        <Center>
          <Image404 />
        </Center>
        <Center>
          <Button
            variant="solid"
            size="lg"
            maxW="50%"
            borderRadius={3}
            mx="auto"
            backgroundColor="pink.500"
            _hover={{
              backgroundColor: 'pink.400',
            }}
            color="white"
          >
            <ActiveLink href="/">go back to home</ActiveLink>
          </Button>
        </Center>
      </Stack>
    </Flex>
  )
}
export default Custom404


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  